export enum PRICING_TYPES {
  DISCOUNTED = 'COMPAREATPRICE',
  MSRP = 'MSRP',
}

export enum DISCOUNT_TYPES {
  PERCENT_OFF = 'PERCENT_OFF',
  COMPLETION = 'COMPLETION',
}

export interface PromoContentProps {
  discountMessaging?: string;
  discountLink?: string;
}
