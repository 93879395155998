import { Fragment } from 'react';

import type {
  WReferencePrice,
  WPromoDisplayView,
  WReferencePriceRange,
} from '@zola/svc-web-api-ts-client';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';

import {
  DiscountText,
  Price,
  PriceOriginal,
  PriceStrokeThrough,
} from '~/components/common/PricingStrikeThrough/PricingStrikeThrough.styles';
import {
  DISCOUNT_TYPES,
  PRICING_TYPES,
  PromoContentProps,
} from '~/components/common/PricingStrikeThrough/types';
import {
  getPriceFractionDigits,
  getPriceLabel,
  formatPromoMessage,
  getRangePrices,
} from '~/components/common/PricingStrikeThrough/utils';
import { convertCurrencyFormat } from '~/util/currencyFormatter';

import { MSRPCore } from '../shop/MSRP/MSRP';

const PromoContent = ({ discountMessaging, discountLink }: PromoContentProps) => {
  if (!discountMessaging && !discountLink) return null;

  return (
    <DiscountText>
      {discountMessaging}{' '}
      <LinkV2
        variant="secondary"
        className="pdp-promo-detail__link"
        target="_blank"
        rel="noopener noreferrer"
        href={discountLink || ''}
        onClick={(e) => e.stopPropagation()}
      >
        Details
      </LinkV2>
    </DiscountText>
  );
};

const getOriginalPriceLabel = (
  id: string,
  discountType?: PRICING_TYPES
): string | React.ReactFragment => {
  let label;

  if (discountType === PRICING_TYPES.MSRP) {
    label = <MSRPCore id={id} />;
  }

  return label || 'Was';
};

export type PricingStrikeThroughProps = {
  isPriceHidden?: boolean;
  referencePrice?: WReferencePrice;
  promoData?: WPromoDisplayView;
  priceRange?: WReferencePriceRange;
  price: number;

  /** A Unique identifier for this price.  Used to generate a unique identifier for the tooltip shown on the MSRP */
  id: string;
};

export const PricingWithStrikeThrough = ({
  isPriceHidden,
  referencePrice,
  promoData,
  priceRange,
  price,
  id,
}: PricingStrikeThroughProps) => {
  if (!price || isPriceHidden) return null;

  let discountLink;
  let discountMessaging;
  let isPostWeddingDiscount;
  const isPromoEnabled = !!promoData;
  const discountType = referencePrice?.type;
  let formattedPrice = convertCurrencyFormat(price, 'USD', getPriceFractionDigits(price), 2);
  let formattedReferencePrice =
    !!discountType && convertCurrencyFormat(referencePrice?.amount, 'USD', 0, 2);

  if (!!priceRange?.min_price && !!priceRange?.max_price) {
    const { lowerBoundPrice: lowerBoundReferencePrice, upperBoundPrice: upperBoundReferencePrice } =
      getRangePrices(referencePrice);
    const { lowerBoundPrice, upperBoundPrice } = getRangePrices(priceRange);
    formattedReferencePrice = upperBoundReferencePrice
      ? `${lowerBoundReferencePrice} - ${upperBoundReferencePrice}`
      : lowerBoundReferencePrice;
    formattedPrice = upperBoundPrice ? `${lowerBoundPrice} - ${upperBoundPrice}` : lowerBoundPrice;
  }

  if (isPromoEnabled) {
    isPostWeddingDiscount = promoData.discount_type === DISCOUNT_TYPES.COMPLETION;
    discountLink = isPostWeddingDiscount
      ? '/wedding-registry/benefits/registry-completion-discount'
      : `/discount-terms/${promoData?.discount_id}`;
    discountMessaging = isPostWeddingDiscount
      ? `${promoData?.discount_amount}% Off With Post-Wedding Discount`
      : formatPromoMessage(promoData);
  }

  const originalPriceLabel = getOriginalPriceLabel(id, discountType as PRICING_TYPES);
  const priceLabel = getPriceLabel(
    isPromoEnabled && isPostWeddingDiscount
      ? DISCOUNT_TYPES.COMPLETION
      : (discountType as PRICING_TYPES)
  );

  return (
    <Fragment>
      {!!discountType && (
        <PriceOriginal>
          {originalPriceLabel} <PriceStrokeThrough>{formattedReferencePrice}</PriceStrokeThrough>
        </PriceOriginal>
      )}
      <Price isDiscounted={!!discountType}>
        {priceLabel}
        {formattedPrice}
      </Price>
      <PromoContent discountMessaging={discountMessaging} discountLink={discountLink} />
    </Fragment>
  );
};
