import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';

import styled from '@emotion/styled';

const DISCOUNT_FONT_SIZE = FONT.FONT_SIZE_SMALL;
export const PriceDiscounted = styled.span`
  color: ${COLORS3.ROSE_100};
  position: absolute;
  left: 0;
  bottom: -${DISCOUNT_FONT_SIZE};
  font-size: ${DISCOUNT_FONT_SIZE};
`;

export const Strikethrough = styled.span`
  text-decoration: line-through;
`;

export const DiscountText = styled.span`
  color: ${COLORS3.ROSE_100};
  display: block;
  font-size: ${FONT.FONT_SIZE_SMALLER};
  line-height: ${FONT.FONT_SIZE_SMALL};
  margin: 0 0 5px 0;
  text-wrap: wrap;
`;
