import DropdownListBox from '@zola/zola-ui/src/components/Form/common/DropdownListBox';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';

import styled from '@emotion/styled';

export const FilterDropdownContainer = styled.div`
  position: relative;
  cursor: pointer;
  font-size: 14px;
`;

export const StyledDropdownListBox = styled(DropdownListBox)`
  min-width: 280px;
  width: unset;
  ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 10px 0;
    list-style: none;
    > li,
    > div {
      padding: 10px 20px;
      &:hover {
        background: var(--registry-filter-hover-item-background, ${COLORS3.BLACK_005});
      }
    }
  }
`;

export const ListOption = styled.li<{ selected?: boolean }>`
  color: var(--text-primary, ${COLORS3.BLACK_100});
  background: ${(props) =>
    props.selected && `var(--registry-filter-selected-item-background, ${COLORS3.BLACK_005})`};
`;

export const FilterButton = styled.div<{ active?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 12px 16px;
  border-radius: 100px;
  background: ${(props) =>
    props.active
      ? `var(--registry-filter-active-background, ${COLORS3.BLACK_015})`
      : `var(--registry-filter-background, ${COLORS3.BLACK_005})`};
  color: var(--text-primary, ${COLORS3.BLACK_100});
  font-weight: ${FONT.FONT_WEIGHT_BOLD};
  :hover {
    background: var(--registry-filter-hover-background, ${COLORS3.BLACK_015});
  }
  svg {
    margin-left: 8px;
  }
`;
