import type { WRegistryCollectionItemView } from '@zola/svc-web-api-ts-client';

import { PricingWithStrikeThrough } from '~/components/common/PricingStrikeThrough/PricingStrikeThrough';

import { PriceContainer, BottomSection } from './RegistryTilePrice.styles';
import { RegistryTileProgressBar } from './RegistryTileProgressBar';

type RegistryTilePriceProps = {
  item: WRegistryCollectionItemView;
};

export const RegistryTilePrice = ({ item }: RegistryTilePriceProps) => {
  if (!item.price) return null;

  const { reference_price: referencePrice = {}, promo_display_view: promoData, price } = item;

  const hasContributionHidden = item?.contributions?.hide_contributions;
  const isGiftProgressVisible =
    item.contributions?.group_gift &&
    !item.contributions?.show_as_fulfilled &&
    !hasContributionHidden;
  const remainPercentage = item.contributions?.percent_complete;

  return (
    <PriceContainer>
      <PricingWithStrikeThrough
        isPriceHidden={hasContributionHidden}
        referencePrice={referencePrice}
        promoData={promoData}
        price={price}
        id={`price-${item.object_id}`}
      />
      {isGiftProgressVisible && (
        <BottomSection>
          <RegistryTileProgressBar percentage={remainPercentage} />
        </BottomSection>
      )}
    </PriceContainer>
  );
};
