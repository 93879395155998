import { InfoCircleIcon } from '@zola/zola-ui/src/components/SvgIconsV3/InfoCircle';
import { TooltipV3 } from '@zola/zola-ui/src/components/TooltipV3/TooltipV3';

import cx from 'classnames';

import styles from './msrp.module.less';

export const MSRPCore = ({ id }: { id: string }) => {
  return (
    <>
      MSRP{' '}
      <span className={styles.tooltip}>
        <InfoCircleIcon title="" showTitle={false} height={20} width={20} id={id} />
        <TooltipV3 anchorSelector={`#${id}`} className={styles.tipText}>
          MSRP refers to the manufacturer’s suggested retail price, which may not be the prevailing
          market price or the regular retail price
        </TooltipV3>
      </span>
    </>
  );
};
export const MSRP = ({ className, msrp, id }: { className?: string; msrp: string; id: string }) => {
  return (
    <div className={cx(styles.msrpPrice, className)}>
      <MSRPCore id={id} />
      {msrp}
    </div>
  );
};
