import { useMemo } from 'react';

import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import SideDrawerV2 from '@zola/zola-ui/src/components/SideDrawerV2';

import { FilterSideDrawerContent } from './FilterSideDrawerContent';
import { FiltersProps } from './type';

interface FilterSideDrawerProps extends FiltersProps {
  displayDrawer: boolean;
  total?: number;
  onClose: () => void;
  onClear?: () => void;
}

export const FilterSideDrawer = ({
  displayDrawer,
  total,
  sortBy,
  showSort,
  filters,
  onClose,
  onClear,
  onChange,
}: FilterSideDrawerProps) => {
  const hasActiveFilters = useMemo(() => {
    return filters.some((filter) =>
      Array.isArray(filter.value) ? filter.value.length : filter.value
    );
  }, [filters]);

  return (
    <SideDrawerV2
      isOpen={displayDrawer}
      showCancelButton
      onClose={onClose}
      onSave={onClose}
      saveButtonText={total ? `Show ${total} results` : 'Show results'}
      title="Filters"
      extraFooterElement={
        hasActiveFilters ? (
          <LinkV2 role="button" onClick={onClear || onClose}>
            Clear
          </LinkV2>
        ) : null
      }
    >
      <FilterSideDrawerContent
        filters={filters}
        sortBy={sortBy}
        showSort={showSort}
        onChange={onChange}
      />
    </SideDrawerV2>
  );
};
