import type { WRegistryCollectionItemContributionView } from '@zola/svc-web-api-ts-client';
import P from '@zola/zola-ui/src/typography/Paragraphs';

import {
  ContributionContainer,
  LargeTileContribution,
  SmallTileContribution,
} from './RegistryTilePrice.styles';

interface ContributionsProps {
  contributions?: WRegistryCollectionItemContributionView;
}

export const RegistryTileContributions: React.FC<ContributionsProps> = ({ contributions }) => {
  if (!contributions) return null;

  const {
    group_gift: isGroupGift,
    still_needs: stillNeeds = 0,
    hide_contributions: hideContributions,
  } = contributions;

  const contributeText = hideContributions
    ? 'Contribute what you wish'
    : `${stillNeeds} still needed`;

  return isGroupGift ? (
    <ContributionContainer>
      <LargeTileContribution>
        <P.BodySmall>{contributeText}</P.BodySmall>
      </LargeTileContribution>
      <SmallTileContribution>
        <P.BodySmall>{contributeText}</P.BodySmall>
      </SmallTileContribution>
    </ContributionContainer>
  ) : (
    <ContributionContainer>
      <LargeTileContribution>
        <P.BodySmall>Asking for {contributions.goal_units}</P.BodySmall>
        <P.BodySmall>Purchased {contributions.completed_units}</P.BodySmall>
      </LargeTileContribution>
      <SmallTileContribution>
        <P.BodySmall>
          {contributions.completed_units} of {contributions.goal_units} purchased
        </P.BodySmall>
      </SmallTileContribution>
    </ContributionContainer>
  );
};
