import { useState } from 'react';

import { AdjustmentsFilterHorizontalIcon } from '@zola/zola-ui/src/components/SvgIconsV3/AdjustmentsFilterHorizontal';
import { TruckDeliveryIcon } from '@zola/zola-ui/src/components/SvgIconsV3/TruckDelivery';
import { useShopContext } from '@zola/zola-ui/src/contexts/ShopContext/ShopContext';

import { FilterSideDrawer } from './FilterSideDrawer';
import {
  FiltersMobileContainer,
  FilterMobileButton,
  FilterMobileGiftCount,
  FilterFloattingButton,
  DeliveryMessage,
} from './MobileFilters.styles';
import { FiltersProps } from './type';

export const MobileFilters = ({
  filters,
  total,
  sortBy,
  showSort,
  onChange,
  onClear,
}: FiltersProps) => {
  const [displayDrawer, setDisplayDrawer] = useState(false);
  const { isBaby } = useShopContext();

  return (
    <FiltersMobileContainer>
      <FilterMobileGiftCount>{total} gifts</FilterMobileGiftCount>
      {showSort ? (
        <>
          {!isBaby && (
            <DeliveryMessage>
              <TruckDeliveryIcon /> Free shipping, every day
            </DeliveryMessage>
          )}
          <FilterFloattingButton onClick={() => setDisplayDrawer(true)}>
            <AdjustmentsFilterHorizontalIcon width={24} height={24} />
            Filter & Sort
          </FilterFloattingButton>
        </>
      ) : (
        <>
          <FilterMobileButton onClick={() => setDisplayDrawer(true)}>
            <AdjustmentsFilterHorizontalIcon width={24} height={24} />
            Filter
          </FilterMobileButton>
        </>
      )}
      <FilterSideDrawer
        filters={filters}
        total={total}
        sortBy={sortBy}
        onChange={onChange}
        displayDrawer={displayDrawer}
        showSort={showSort}
        onClose={() => setDisplayDrawer(false)}
        onClear={onClear}
      />
    </FiltersMobileContainer>
  );
};
