import type { CustomTab } from '@zola-helpers/client/dist/es/uploadcare/types';

import type { DialogApi, Settings } from '@uploadcare/react-widget';
import uploadcare from 'uploadcare-widget';

import {
  WEDDING_REGISTRY_HERO_BACKGROUND_IMAGES,
  BackgroundImage,
  backgroundImageTabSvg,
  WEDDING_REGISTRY_CASH_FUND_IMAGES,
  BABY_CASH_FUND_IMAGES,
} from './backgroundImageConstants';

const PredefinedImageTabConstructor =
  (
    id:
      | 'manage-registry-uploadcare-background-image-tab'
      | 'wedding-cash-fund-uploadcare-background-image-tab'
      | 'baby-cash-fund-uploadcare-background-image-tab',
    images: BackgroundImage[]
  ) =>
  (container: JQuery, button: JQuery, dialogApi: DialogApi, settings: Settings) => {
    const $ = uploadcare.jQuery;

    const selectImage = (image: BackgroundImage) => {
      const ucfile = uploadcare.fileFrom('url', image.url, settings);
      dialogApi.addFiles([ucfile]);
    };

    // ----- RENDERING -----
    const tabElement = $(`
    <div id="${id}">
      <div class="uploadcare-background-image-tab__title">Choose from our exclusive gallery or upload your own</div>
    </div>
  `);
    const gridElement = $(`<div class="uploadcare-background-image-tab__grid"></div>`);

    images.forEach((image) => {
      const gridItemElement = $(`
      <div class="uploadcare-background-image-tab__gridItem">
        <img class="uploadcare-background-image-tab__image" id="${image.name}" src="${image.thumbnail}">
      </div>
    `);
      gridItemElement.on('click', () => selectImage(image));
      gridElement.append(gridItemElement);
    });

    tabElement.append(gridElement);
    container.append(tabElement);
  };

const PredefinedWeddingRegistryHeroTabConstructor = (
  container: JQuery,
  button: JQuery,
  dialogApi: DialogApi,
  settings: Settings
) =>
  PredefinedImageTabConstructor(
    'manage-registry-uploadcare-background-image-tab',
    WEDDING_REGISTRY_HERO_BACKGROUND_IMAGES
  )(container, button, dialogApi, settings);

export const WeddingRegistryGalleryTab: CustomTab = {
  key: 'registry-background-gallery',
  constructor: PredefinedWeddingRegistryHeroTabConstructor,
  sidebarTitle: 'Zola Exclusives',
  sidebarIcon: backgroundImageTabSvg,
};

const PredefinedWeddingCashFundTabConstructor = (
  container: JQuery,
  button: JQuery,
  dialogApi: DialogApi,
  settings: Settings
) =>
  PredefinedImageTabConstructor(
    'wedding-cash-fund-uploadcare-background-image-tab',
    WEDDING_REGISTRY_CASH_FUND_IMAGES
  )(container, button, dialogApi, settings);

export const WeddingCashFundGalleryTab: CustomTab = {
  key: 'wedding-cash-fund-background-gallery',
  constructor: PredefinedWeddingCashFundTabConstructor,
  sidebarTitle: 'Zola Exclusives',
  sidebarIcon: backgroundImageTabSvg,
};

const PredefinedBabyCashFundTabConstructor = (
  container: JQuery,
  button: JQuery,
  dialogApi: DialogApi,
  settings: Settings
) =>
  PredefinedImageTabConstructor(
    'baby-cash-fund-uploadcare-background-image-tab',
    BABY_CASH_FUND_IMAGES
  )(container, button, dialogApi, settings);

export const BabyCashFundGalleryTab: CustomTab = {
  key: 'baby-cash-fund-background-gallery',
  constructor: PredefinedBabyCashFundTabConstructor,
  sidebarTitle: 'Zola Exclusives',
  sidebarIcon: backgroundImageTabSvg,
};
