import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';

import styled from '@emotion/styled';

export const FiltersContainer = styled.div`
  padding: var(--filters-container-padding, 16px 0);
  ${MEDIA_QUERY.DESKTOP} {
    padding: var(--filters-container-desktop-padding, 24px 0);
  }
`;

export const FilterPillsContainer = styled.div`
  margin-top: 16px;
`;
