import type {
  WRegistryCollectionBaseView,
  WAwsFacet,
  WAwsBucket,
} from '@zola/svc-web-api-ts-client';

import type { Option, Filter } from '~/components/common/Filters/type';

export const mapCollectionToOption = (collection: WRegistryCollectionBaseView): Option => ({
  label: collection.title,
  value: collection.object_id,
});

export const getCollectionOption = (collection?: WRegistryCollectionBaseView[]): Option[] =>
  collection?.length
    ? [{ label: 'All Categories', value: '' }, ...(collection || []).map(mapCollectionToOption)]
    : [];

export const mapWAwsBucketToOption = (backet: WAwsBucket): Option => ({
  label: backet.label,
  value: backet.value,
});

export const mapWAwsFacetToFilter = (facet: WAwsFacet, value: string[] = []): Filter => ({
  key: facet.key || '',
  label: facet.label || '',
  value,
  options: facet.options?.map(mapWAwsBucketToOption) || [],
  isMulti: true,
});
