import { useCallback, useRef, useState } from 'react';

import { RadioField } from '@zola/zola-ui/src/components/Form/RadioFieldV2/RadioField';
import { ChevronDownIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ChevronDown';
import { useClickOutside } from '@zola/zola-ui/src/hooks/useClickOutside';
import { createKeyClickHandler } from '@zola/zola-ui/src/util/handleKeys';

import { SORTS } from './constants';
import {
  FilterDropdownContainer,
  StyledDropdownListBox,
  FilterButton,
} from './FilterDropdown.styles';

interface SortDropdownProps {
  value: string;
  onChange?: (val: string) => void;
}

const SortOption = ({
  option,
  checked,
  handleChange,
}: {
  option: (typeof SORTS)[0];
  checked: boolean;
  handleChange: (val: string) => void;
}) => {
  const onChange = useCallback(() => {
    handleChange(option.value);
  }, [handleChange, option.value]);

  const onKeyUp = useCallback((event) => createKeyClickHandler(onChange)(event), [onChange]);

  return (
    <div role="button" tabIndex={0} onKeyUp={onKeyUp}>
      <RadioField id={option.label} label={option.label} onChange={onChange} checked={checked} />
    </div>
  );
};

export const SortDropdown = ({ value, onChange }: SortDropdownProps) => {
  const [expanded, setExpanded] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside({ ref, onClickOutside: () => setExpanded(false), isOpen: expanded });

  const handleSortChange = useCallback(
    (val: string) => {
      if (!onChange) return;

      onChange(val);
      setExpanded(false);
    },
    [onChange]
  );

  const toggleMenu = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const onKeyUp = useCallback((event) => createKeyClickHandler(toggleMenu)(event), [toggleMenu]);

  return (
    <FilterDropdownContainer ref={ref}>
      <FilterButton
        onClick={() => setExpanded((currentStatus) => !currentStatus)}
        active={expanded}
        role="button"
        tabIndex={0}
        onKeyUp={onKeyUp}
      >
        Sort: {SORTS.find((option) => option.value === value)?.label}
        <ChevronDownIcon showTitle={false} />
      </FilterButton>
      {expanded && (
        <StyledDropdownListBox onClose={() => setExpanded(false)}>
          {SORTS.map((option, index) => (
            <SortOption
              key={index}
              option={option}
              handleChange={handleSortChange}
              checked={value === option.value}
            />
          ))}
        </StyledDropdownListBox>
      )}
    </FilterDropdownContainer>
  );
};
