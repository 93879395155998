import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';

import styled from '@emotion/styled';

export const FiltersMobileContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;

  ${MEDIA_QUERY.DESKTOP} {
    display: none;
  }
`;

export const FilterMobileButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    margin-right: 8px;
  }
`;

export const FilterMobileGiftCount = styled.div`
  margin-right: auto;
`;

export const DeliveryMessage = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

export const FilterFloattingButton = styled(ButtonV3)`
  display: flex;
  column-gap: 8px;
  position: fixed;
  bottom: 40px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
`;
