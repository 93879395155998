import { PRICING_TYPES, DISCOUNT_TYPES } from '~/components/common/PricingStrikeThrough/types';
import { convertCurrencyFormat } from '~/util/currencyFormatter';

import type { PricingStrikeThroughProps } from './PricingStrikeThrough';

export const getPriceFractionDigits = (price: number): number => ((price / 100) % 1 > 0 ? 2 : 0);

export const getPriceLabel = (pricingType: DISCOUNT_TYPES.COMPLETION | PRICING_TYPES): string => {
  switch (pricingType) {
    case PRICING_TYPES.DISCOUNTED:
      return `Sale `;
    case PRICING_TYPES.MSRP:
      return `Our price `;
    default:
      return '';
  }
};

export const formatPromoMessage = (promoData: PricingStrikeThroughProps['promoData']) => {
  if (!promoData) return '';

  const promoDiscountAmount = promoData.discount_amount || 0;
  const promoDiscountMinimumSpend = promoData.minimum_spend || 0;
  const priceInDollar = convertCurrencyFormat(promoDiscountAmount / 100, 'USD', 0, 0);
  if (promoDiscountMinimumSpend > 0) {
    const minPriceInDollar = convertCurrencyFormat(promoDiscountMinimumSpend / 100, 'USD', 0, 0);
    return promoData.discount_type === DISCOUNT_TYPES.PERCENT_OFF
      ? `${promoDiscountAmount}% off ${minPriceInDollar}+ with code ${promoData.code}`
      : `${priceInDollar} off ${minPriceInDollar}+ with code ${promoData.code}`;
  }

  return promoData.discount_type === DISCOUNT_TYPES.PERCENT_OFF
    ? `${promoDiscountAmount}% off with code`
    : `${priceInDollar} off with code`;
};

export const getRangePrices = (priceRange: PricingStrikeThroughProps['priceRange']) => {
  const rangePriceMin = priceRange?.min_price || 0;
  const rangePriceMax = priceRange?.max_price || 0;

  const lowerBoundPrice = convertCurrencyFormat(
    rangePriceMin / 100,
    'USD',
    getPriceFractionDigits(rangePriceMin),
    2
  );
  const upperBoundPrice =
    rangePriceMin < rangePriceMax
      ? convertCurrencyFormat(rangePriceMax / 100, 'USD', getPriceFractionDigits(rangePriceMax), 2)
      : '';

  return {
    lowerBoundPrice,
    upperBoundPrice,
  };
};
