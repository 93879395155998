import { useCallback, useRef, useState } from 'react';

import { CheckboxField } from '@zola/zola-ui/src/components/Form/CheckboxField';
import { ChevronDownIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ChevronDown';
import { useClickOutside } from '@zola/zola-ui/src/hooks/useClickOutside';
import { createKeyClickHandler } from '@zola/zola-ui/src/util/handleKeys';
import capitalizeFirst from '@zola-helpers/client/dist/es/transformers/capitalizeFirst';

import {
  FilterDropdownContainer,
  StyledDropdownListBox,
  FilterButton,
  ListOption,
} from './FilterDropdown.styles';
import type { Filter, Option } from './type';

interface FilterDropdownProps {
  filter: Filter;
  onChange: (key: string, val: string) => void;
}

const SingleChoiceOption = ({
  option,
  selected,
  handleChange,
}: {
  option: Option;
  selected: boolean;
  handleChange: (val?: string) => void;
}) => {
  const onClick = useCallback(() => {
    handleChange(option.value);
  }, [handleChange, option.value]);

  const onKeyUp = useCallback((event) => createKeyClickHandler(onClick)(event), [onClick]);

  return (
    <ListOption
      id={option.label || ''}
      selected={selected}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyUp={onKeyUp}
    >
      {option.label}
    </ListOption>
  );
};

const MultipleChoiceOption = ({
  option,
  selected,
  handleChange,
}: {
  option: Option;
  selected: boolean;
  handleChange: (val?: string) => void;
}) => {
  const onClick = useCallback(() => {
    handleChange(option.value);
  }, [handleChange, option.value]);

  const onKeyUp = useCallback((event) => createKeyClickHandler(onClick)(event), [onClick]);

  return (
    <div onKeyUp={onKeyUp} tabIndex={0} role="button">
      <CheckboxField
        id={option.label || ''}
        label={option.label}
        onChange={() => handleChange(option.value)}
        checked={selected}
      />
    </div>
  );
};

export const FilterDropdown = ({ filter, onChange }: FilterDropdownProps) => {
  const { key, label, value, options, isMulti } = filter;
  const [expanded, setExpanded] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside({ ref, onClickOutside: () => setExpanded(false), isOpen: expanded });

  const changeFilterAndClose = useCallback(
    (val?: string) => {
      onChange(key, val || '');
      setExpanded(false);
    },
    [key, onChange]
  );

  const toggleMenu = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const onKeyUp = useCallback((event) => createKeyClickHandler(toggleMenu)(event), [toggleMenu]);

  return (
    <FilterDropdownContainer ref={ref}>
      <FilterButton
        onClick={toggleMenu}
        active={Boolean(value?.length) || expanded}
        role="button"
        tabIndex={0}
        onKeyUp={onKeyUp}
      >
        {capitalizeFirst(label, 1)} {value?.length && isMulti ? `(${value.length})` : undefined}{' '}
        <ChevronDownIcon showTitle={false} />
      </FilterButton>
      {isMulti && expanded && (
        <StyledDropdownListBox onClose={() => setExpanded(false)}>
          {options?.map((option, index) => (
            <MultipleChoiceOption
              handleChange={(val) => onChange(key, val || '')}
              key={index}
              option={option}
              selected={Boolean(value?.includes(option.value || ''))}
            />
          ))}
        </StyledDropdownListBox>
      )}
      {!isMulti && expanded && (
        <StyledDropdownListBox onClose={() => setExpanded(false)}>
          <ul>
            {options?.map((option, index) => (
              <SingleChoiceOption
                handleChange={changeFilterAndClose}
                key={index}
                option={option}
                selected={option.value === value}
              />
            ))}
          </ul>
        </StyledDropdownListBox>
      )}
    </FilterDropdownContainer>
  );
};
