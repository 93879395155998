import { CustomTabIcon } from '@zola-helpers/client/dist/es/uploadcare/types';

const DOMAIN = 'https://d20t6we9nb6rnn.cloudfront.net/';

export const backgroundImageTabSvg: CustomTabIcon = {
  viewBox: '0 0 24 24',
  fill: 'none',
  svgContent: `
    <g>
      <path
        d="M15 8h.01"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="4"
        y="4"
        width="16"
        height="16"
        rx="3"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m4 15 4-4c.928-.893 2.072-.893 3 0l5 5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m14 14 1-1c.928-.893 2.072-.893 3 0l2 2"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  `,
};

export type BackgroundImage = {
  name: string;
  key: string;
  thumbnail: string;
  url: string;
};

export const WEDDING_REGISTRY_HERO_BACKGROUND_IMAGES: BackgroundImage[] = [
  {
    name: '_1',
    key: 'gallery/cover/v2/_1.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_1-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_1.jpg`,
  },
  {
    name: '_10',
    key: 'gallery/cover/v2/_10.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_10-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_10.jpg`,
  },
  {
    name: '_11',
    key: 'gallery/cover/v2/_11.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_11-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_11.jpg`,
  },
  {
    name: '_12',
    key: 'gallery/cover/v2/_12.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_12-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_12.jpg`,
  },
  {
    name: '_14',
    key: 'gallery/cover/v2/_14.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_14-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_14.jpg`,
  },
  {
    name: '_15',
    key: 'gallery/cover/v2/_15.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_15-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_15.jpg`,
  },
  {
    name: '_16',
    key: 'gallery/cover/v2/_16.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_16-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_16.jpg`,
  },
  {
    name: '_17',
    key: 'gallery/cover/v2/_17.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_17-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_17.jpg`,
  },
  {
    name: '_18',
    key: 'gallery/cover/v2/_18.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_18-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_18.jpg`,
  },
  {
    name: '_19',
    key: 'gallery/cover/v2/_19.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_19-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_19.jpg`,
  },
  {
    name: '_2',
    key: 'gallery/cover/v2/_2.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_2-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_2.jpg`,
  },
  {
    name: '_20',
    key: 'gallery/cover/v2/_20.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_20-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_20.jpg`,
  },
  {
    name: '_21',
    key: 'gallery/cover/v2/_21.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_21-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_21.jpg`,
  },
  {
    name: '_22',
    key: 'gallery/cover/v2/_22.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_22-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_22.jpg`,
  },
  {
    name: '_23',
    key: 'gallery/cover/v2/_23.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_23-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_23.jpg`,
  },
  {
    name: '_24',
    key: 'gallery/cover/v2/_24.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_24-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_24.jpg`,
  },
  {
    name: '_25',
    key: 'gallery/cover/v2/_25.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_25-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_25.jpg`,
  },
  {
    name: '_26',
    key: 'gallery/cover/v2/_26.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_26-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_26.jpg`,
  },
  {
    name: '_27',
    key: 'gallery/cover/v2/_27.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_27-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_27.jpg`,
  },
  {
    name: '_28',
    key: 'gallery/cover/v2/_28.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_28-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_28.jpg`,
  },
  {
    name: '_29',
    key: 'gallery/cover/v2/_29.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_29-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_29.jpg`,
  },
  {
    name: '_3',
    key: 'gallery/cover/v2/_3.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_3-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_3.jpg`,
  },
  {
    name: '_30',
    key: 'gallery/cover/v2/_30.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_30-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_30.jpg`,
  },
  {
    name: '_31',
    key: 'gallery/cover/v2/_31.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_31-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_31.jpg`,
  },
  {
    name: '_32',
    key: 'gallery/cover/v2/_32.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_32-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_32.jpg`,
  },
  {
    name: '_33',
    key: 'gallery/cover/v2/_33.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_33-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_33.jpg`,
  },
  {
    name: '_34',
    key: 'gallery/cover/v2/_34.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_34-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_34.jpg`,
  },
  {
    name: '_35',
    key: 'gallery/cover/v2/_35.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_35-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_35.jpg`,
  },
  {
    name: '_36',
    key: 'gallery/cover/v2/_36.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_36-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_36.jpg`,
  },
  {
    name: '_37',
    key: 'gallery/cover/v2/_37.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_37-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_37.jpg`,
  },
  {
    name: '_38',
    key: 'gallery/cover/v2/_38.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_38-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_38.jpg`,
  },
  {
    name: '_39',
    key: 'gallery/cover/v2/_39.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_39-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_39.jpg`,
  },
  {
    name: '_4',
    key: 'gallery/cover/v2/_4.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_4-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_4.jpg`,
  },
  {
    name: '_40',
    key: 'gallery/cover/v2/_40.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_40-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_40.jpg`,
  },
  {
    name: '_41',
    key: 'gallery/cover/v2/_41.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_41-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_41.jpg`,
  },
  {
    name: '_42',
    key: 'gallery/cover/v2/_42.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_42-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_42.jpg`,
  },
  {
    name: '_43',
    key: 'gallery/cover/v2/_43.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_43-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_43.jpg`,
  },
  {
    name: '_44',
    key: 'gallery/cover/v2/_44.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_44-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_44.jpg`,
  },
  {
    name: '_45',
    key: 'gallery/cover/v2/_45.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_45-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_45.jpg`,
  },
  {
    name: '_46',
    key: 'gallery/cover/v2/_46.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_46-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_46.jpg`,
  },
  {
    name: '_47',
    key: 'gallery/cover/v2/_47.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_47-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_47.jpg`,
  },
  {
    name: '_48',
    key: 'gallery/cover/v2/_48.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_48-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_48.jpg`,
  },
  {
    name: '_49',
    key: 'gallery/cover/v2/_49.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_49-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_49.jpg`,
  },
  {
    name: '_5',
    key: 'gallery/cover/v2/_5.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_5-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_5.jpg`,
  },
  {
    name: '_50',
    key: 'gallery/cover/v2/_50.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_50-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_50.jpg`,
  },
  {
    name: '_51',
    key: 'gallery/cover/v2/_51.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_51-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_51.jpg`,
  },
  {
    name: '_52',
    key: 'gallery/cover/v2/_52.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_52-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_52.jpg`,
  },
  {
    name: '_6',
    key: 'gallery/cover/v2/_6.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_6-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_6.jpg`,
  },
  {
    name: '_7',
    key: 'gallery/cover/v2/_7.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_7-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_7.jpg`,
  },
  {
    name: '_8',
    key: 'gallery/cover/v2/_8.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_8-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_8.jpg`,
  },
  {
    name: '_9',
    key: 'gallery/cover/v2/_9.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/_9-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/_9.jpg`,
  },
  {
    name: 'cover1',
    key: 'gallery/cover/v2/cover1.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/cover1-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/cover1.jpg`,
  },
  {
    name: 'cover2',
    key: 'gallery/cover/v2/cover2.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/cover2-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/cover2.jpg`,
  },
  {
    name: 'cover3',
    key: 'gallery/cover/v2/cover3.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/cover3-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/cover3.jpg`,
  },
  {
    name: 'cover4',
    key: 'gallery/cover/v2/cover4.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/cover4-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/cover4.jpg`,
  },
  {
    name: 'cover6',
    key: 'gallery/cover/v2/cover6.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/cover6-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/cover6.jpg`,
  },
  {
    name: 'cover7',
    key: 'gallery/cover/v2/cover7.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/cover7-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/cover7.jpg`,
  },
  {
    name: 'cover9',
    key: 'gallery/cover/v2/cover9.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/cover9-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/cover9.jpg`,
  },
  {
    name: 'cover12',
    key: 'gallery/cover/v2/cover12.jpg',
    thumbnail: `${DOMAIN}gallery/cover/v2/cover12-thumb.jpg`,
    url: `${DOMAIN}gallery/cover/v2/cover12.jpg`,
  },
];

export const WEDDING_REGISTRY_CASH_FUND_IMAGES: BackgroundImage[] = [
  'cash_default_640',
  'Backpacking_Gear_A_640',
  'Baking_Class_A_640',
  'Baseball_Tickets_A_640',
  'Breakfast_in_Bed_A_640',
  'Champagne_and_Berries_A_640',
  'Charity_Fund_A_640',
  'Cottage_in_the_Snow_A_640',
  'Couples_Massage_A_640',
  'Drinks_with_a_View_A_640',
  'Fishing_Charter_A_640',
  'Hiking_Tour_A_640',
  'Honeymoon_Fund_A_640',
  'House_Down_Payment_A_640',
  'Housecleaning_Services_A_640',
  'Kitchen_Remodel_A_640',
  'Opera_Tickets_A_640',
  'Picnic_for_Two_A_640',
  'Private_Trainer_A_640',
  'Resort_A_640',
  'Romantic_Dinners_A_640',
  'Round_Trip_Airfare_A_640',
  'Safari_A_640',
  'Skiing_Gear_A_640',
  'Snorkeling_for_Two_A_640',
  'Thank_You_Cards_A_640',
  'Urban_Hotel_A_640',
  'Watersports_Activities_A_640',
  'Wedding_Cake_A_640',
  'Wedding_Cinematographer_A_640',
  'Wedding_Flowers_A_640',
  'Wine_Tasting_A_640',
].map((name) => {
  const path = `gallery/products/${name}`;
  return {
    name,
    key: `${path}.jpg`,
    thumbnail: `${DOMAIN}${path}-thumb.jpg`,
    url: `${DOMAIN}${path}.jpg`,
  };
});

export const BABY_CASH_FUND_IMAGES: BackgroundImage[] = [
  'woman_in_orange_640',
  'wellness_640',
  'toys_640',
  'teddy_bears_640',
  'sage_pattern_640',
  'piggy_bank_640',
  'nursery_640',
  'mobile_640',
  'having_a_baby_640',
  'feet_640',
  'doula_640',
  'doctor_640',
  'diaper_bag_640',
  'college_fund_640',
  'bottles_640',
].map((name) => {
  const path = `gallery/baby/cash/${name}`;
  return {
    name,
    key: `${path}.jpg`,
    // Baby images don't have thumbnails in the s3 bucket
    thumbnail: `${DOMAIN}${path}.jpg`,
    url: `${DOMAIN}${path}.jpg`,
  };
});
