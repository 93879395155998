import type {
  CheckoutViewResponse,
  CreatePaymentIntentRequest,
  Submit,
  WCheckoutResponseView,
  WDeleteCartItemsRequest,
  WGetCartView,
  WGetCartViewResponse,
  WPaymentIntentView,
} from '@zola/svc-web-api-ts-client';
import type { ConvertWebApiDeclaredToActual } from '@zola-helpers/client/dist/es/@types/svc-web-api';
import ApiService from '@zola-helpers/client/dist/es/http/api';

import type { CamelCasedPropertiesDeep } from 'type-fest';

type ResponseType<T> = {
  data: T;
};

export type CartResponseType = CamelCasedPropertiesDeep<
  ConvertWebApiDeclaredToActual<WGetCartViewResponse>
>;

export type CartDetailsResponseType = CamelCasedPropertiesDeep<
  ConvertWebApiDeclaredToActual<CheckoutViewResponse>
>;

export type PaymentIntentView = CamelCasedPropertiesDeep<
  ConvertWebApiDeclaredToActual<WPaymentIntentView>
>;

export const fetchCart = () => {
  return ApiService.get<
    CamelCasedPropertiesDeep<ConvertWebApiDeclaredToActual<WGetCartViewResponse>>
  >('/web-registry-api/v1/cart');
};

export const fetchInitialCartDetails = () => {
  return ApiService.get<
    CamelCasedPropertiesDeep<ConvertWebApiDeclaredToActual<CheckoutViewResponse>>
  >('/web-registry-api/v1/checkout');
};

export const getCartByStore = (store: string) => {
  return ApiService.get<ResponseType<WGetCartView>>(
    `/web-registry-api/v1/cart?store=${store}`
  ).then((response) => response.data);
};

export const removeCartItems = (itemIds: string[], store?: string) => {
  const body: Omit<WDeleteCartItemsRequest, 'user_id' | 'ip_address' | 'user_agent'> = {
    item_ids: itemIds,
    store: store as unknown as WDeleteCartItemsRequest.StoreEnum | undefined,
  };
  return ApiService.post<ResponseType<WGetCartView>>(`/web-registry-api/v1/cart/items`, body).then(
    (response) => response.data
  );
};

export type CreatePaymentIntentBody = CamelCasedPropertiesDeep<
  Omit<CreatePaymentIntentRequest, 'ip_address' | 'user_agent'>
>;

export const createPaymentIntent = (body: CreatePaymentIntentRequest) => {
  return ApiService.post<
    PaymentIntentView,
    CamelCasedPropertiesDeep<Omit<CreatePaymentIntentRequest, 'ip_address' | 'user_agent'>>
  >('/web-registry-api/v1/checkout/create-payment-intent', body);
};

export type CheckoutRequest = Omit<
  Submit,
  | 'user_id'
  | 'ip_address'
  | 'user_agent'
  | 'store'
  | 'guest'
  | 'post_auth_click_id'
  | 'order_key'
  | 'impact_radius_click_id'
>;

export const submitCheckout = (body: CheckoutRequest) => {
  return ApiService.post<
    CamelCasedPropertiesDeep<ConvertWebApiDeclaredToActual<WCheckoutResponseView>>,
    CheckoutRequest
  >('/web-registry-api/v1/checkout', body);
};
