import capitalizeFirst from '@zola-helpers/client/dist/es/transformers/capitalizeFirst';

import { v4 as uuidv4 } from 'uuid';

import type { Filter, Option } from './type';

/**
 * Default maximum filter's name to display
 */
const MAX_FILTER = 6;

export const getActiveFilterLabels = (filter: (string | undefined)[] | undefined): string => {
  if (!filter) return '';

  const labels =
    filter
      ?.filter((str): str is string => str !== undefined)
      .slice(0, MAX_FILTER)
      .map(capitalizeFirst) || [];

  if (filter?.length > MAX_FILTER) {
    labels.push(`+${filter.length - MAX_FILTER} more`);
  }

  return labels.join(', ');
};

const bySelected = (filter: Filter) => (option: Option) =>
  filter.isMulti ? filter.value?.includes(option.value || '') : filter.value === option.value;

const mapFilterOptionToPillOption = (filter: Filter) =>
  filter.options.filter(bySelected(filter)).map((option) => ({
    value: uuidv4(),
    label: option.label,
    filterKey: filter.key,
    filterVal: option.value,
  }));

export const getFilterPillOptions = (filters: Filter[]) =>
  filters
    .filter((filter) => filter.value)
    .map(mapFilterOptionToPillOption)
    .flat();
